import React from "react";
import Layout from "../components/layout";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { StyledH1, StyledSectionWrapper } from "../theme/styles";
import t from "../theme/theme";

const StyledImg = styled.img`
  max-width: 100%;
`;

const StyledShopList = styled.ul`
  list-style-type: none;
  padding-left: ${t.spacing(1)};
  padding-right: ${t.spacing(3)};
  padding-top: 15vh;
`;

const StyledShopItem = styled.li`
  margin-bottom: ${t.spacing(4)};
  color: ${t.blue};
  img {
    display: block;
    width: 100%;
    border-bottom: 2px solid ${t.blue};
    margin-bottom: ${t.spacing(1)};
  }
`;

const NewsPage = (props) => {
  const data = props.data.allShopifyProducts.edges;
  return (
    <Layout location={props.location}>
      <StyledSectionWrapper>
        <StyledH1>Shop</StyledH1>
        <StyledShopList>
          {data.map((shopItem) => (
            <StyledShopItem key={shopItem.node.id}>
              <Link to={`/shop/${shopItem.node.handle}`}>
                <StyledImg
                  src={shopItem.node.images.edges[0].node.src}
                  alt=""
                />
                <div>{shopItem.node.title}</div>
              </Link>
            </StyledShopItem>
          ))}
        </StyledShopList>
      </StyledSectionWrapper>
    </Layout>
  );
};

export default NewsPage;

export const pageQuery = graphql`
  query ShopQuery {
    allShopifyProducts {
      edges {
        node {
          title
          id
          handle
          images {
            edges {
              node {
                src
              }
            }
          }
        }
      }
    }
  }
`;
